<template>
  <div class="input-wrapper">
    <label :for="fieldName" class="label">{{ label }}
      <figure v-if="icon" class="icon-wrapper">
        <img :src="icon" alt="icon" class="icon"/>
      </figure>
      <input ref="inputField"
             :type="type"
             :id="fieldName"
             :name="fieldName"
             :class="{'has-error': errorMessages.length || hasError || apiError}, {'input-field-with-icon': icon}"
             :placeholder="placeholder"
             v-model="input"
             :readonly="isReadOnly"
             :maxlength="maxLength"
             :disabled="isReadOnly"
             @input="(event) => { handleInput(event); $emit('update-parent', event.target.value); }"
             class="input">
    </label>
    <p v-for="errorMessage of errorMessages" :key="errorMessage" class="error-message">{{ errorMessage }}</p>
    <p v-if="apiError" class="error-message">{{apiError}}</p>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import Validator from "@/utils/yo-validator/YoValidator";

export default {
  name: "BasicInputField",
  props: {
    type: {
      default: 'text'
    },
    placeholder: {
      required: false,
      type: String
    },
    label: {
      required: false,
      default: '',
      type: String
    },
    fieldName: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false
    },
    apiError: {
      type: String,
      required: false
    },
    value: {
      required: false,
      default: ''
    },
    hasCheckbox: {
      required: false,
      default: false,
      type: Boolean
    },
    displayErrorMsg: {
      required: false,
      default: true,
      type: Boolean
    },
    icon: {
      required: false,
      default: '',
      type: String
    }
  },
  emits: ['update-parent','error','error-cleared'],
  setup(props,{emit}) {
    const {handleInput, field, errorMessages, setFieldData} = Validator.register(props);
    const input = ref('');

    setValueOnCreate();

    function setValueOnCreate() {
      if (props.value) {
        input.value = props.value;
        setFieldData(props.value);
      }
    }

    watch(() => props.value, (newValue) => {
      input.value = newValue;
      setFieldData(newValue);
    });

    return {
      input,
      errorMessages,
      handleInput,
      field,
    }
  }
}
</script>

<style lang="scss" scoped>
.has-error {
  border: 1px solid var(--red-error)
}

.read-only {
  .input {
    opacity: 0.4;
  }
}

.input-wrapper {
  position: relative;
}

.label {
  position: relative;
}

.icon-wrapper {
  position: absolute;
  transform: translate(rem(8), 55%);

  .icon {
    width: rem(20);
    height: rem(20);
  }
}

.input-field-with-icon {
  padding-left: rem(32);
}

.input-create-new {
  .input {
    color: var(--main-font-color);
  }
}

/** Special styles **/
.selected-option {
  opacity: initial;
}

.answers {
  .input-wrapper {
    margin-left: rem(38);
  }
}

</style>