import Page from "@/reader/model/summary/content/Page";
import CommandTag from "@/reader/model/summary/content/tags/CommandTag";

export default class PageDivider {
  /**
   * @type {Page[]}
   * The list of pages in this document.
   */
  pages = [];

  /**
   *
   * @param {AnnotatedStructuralElement[]} StructuralElements
   * @param containerElement
   * @param scrollWrapperElement
   */
  constructor(StructuralElements) {
    this.structuralElements = StructuralElements;
    this.scrollWrapperElement = document.querySelector('html');
  }

  /**
   * @param {AnnotatedDocument} fullDocument
   * @return {Page[]}
   */
  calculatePages(fullDocument) {
    /** @type {Page[]} */
    const pages = [];
    let pageCount = 1;
    let elementsOnThisPage = [];
    let currentChapter = null;
    let hasBorder = false;
    let lastIsParagraph = false;

    let previousChapter = null;

    /** @type {AnnotatedStructuralElement} */
    let previousElement = null;

    let currentHeight = 0;
    /// The 100 takes into account bottom overlay of the reader.
    let maxPageHeight = this.scrollWrapperElement.clientHeight - 100;

    this.structuralElements.forEach((structuralElement, index) => {
        previousChapter = currentChapter;
        currentChapter = structuralElement.containingChapter ?? currentChapter;

        const isTableOfContents = currentChapter?.title?.toLowerCase().includes("inhoudsopgave")
            || currentChapter?.title?.toLowerCase().includes("table of contents")
            || structuralElement.tableOfContents;

        const isHiddenChapter = currentChapter?.title?.toLowerCase().includes(CommandTag.hideChapterTag);

        const isNextElementABulletPoint = this.structuralElements[index + 1]?.paragraph?.bullet;

        const shouldShow = true; // onlyFreeChapters ? freeChapterIds.includes(structuralElement.containingChapter.chapterId) : true;
        if ((!shouldShow || isTableOfContents || isHiddenChapter) && this.structuralElements[this.structuralElements.length - 1] !== structuralElement) {
            return;
        }

        const isTable = structuralElement.table != null;
        const elementHeight = structuralElement.estimateHeight(this.scrollWrapperElement.clientWidth, fullDocument);

        currentHeight += elementHeight;

        // If adding the current table would exceed the max page height, force a page break after adding the table
        if (isTable && currentHeight > maxPageHeight && elementsOnThisPage.length > 0) {
            elementsOnThisPage.push(structuralElement);

            const newPage = new Page(
                pageCount,
                elementsOnThisPage[0].containingChapter,
                elementsOnThisPage,
            );

            elementsOnThisPage.forEach(structuralElement => {
                structuralElement.setPage(newPage);
                structuralElement.getChildStructuralElements().forEach(childStructuralElement => {
                    childStructuralElement.setPage(newPage);
                });
            });
            elementsOnThisPage = [];

            // Check the page actually has some content.
            if (newPage.annotatedStructuralElements.some(
                (element) => {
                    if (element.table) return true;
                    if (element.paragraph) {
                        return element.paragraph.fullText.trim().length > 0 || element.paragraph.numberOfImages() > 0;
                    }
                    return false;
                }
            )) {
                if (!isHiddenChapter) {
                    pages.push(newPage);
                    pageCount++;
                }
            }

            currentHeight = 0;
            return;
        }

        // If we move to a new chapter, then we will break here.
        // Sometimes the chapters consist of one sentence, therefore we also check on the content height
        const movedToNewChapter = previousChapter !== currentChapter;

        // Check also that the new chapter is only a heading one chapter
        const isHeadingOneChapter = structuralElement.containingChapter.priority === 1;
        const isHeadingTwoChapter = structuralElement.containingChapter.priority === 2;

        // Check if Heading 2 is right after another header, then we don't break
        const isRightAfterOtherHeader = isHeadingTwoChapter && (
            (previousElement?.paragraph?.getHeadingTag() === 'h2' && previousElement?.paragraph?.fullText.trim().length > 0)
            || !lastIsParagraph
        );

        if (movedToNewChapter && previousChapter) {
            previousChapter.endPage = pageCount;
            lastIsParagraph = false;
        }

        // Check if current element is a paragraph
        if (structuralElement?.paragraph?.fullText.trim().length !== 0 && structuralElement?.paragraph?.getHeadingTag() === 'div') {
            lastIsParagraph = true;
        }

        const isFirstParagraphOfChapter = previousElement?.paragraph?.getHeadingTag() !== 'div' ?? false;

        const hasImage = structuralElement?.paragraph?.numberOfImages() > 0;

        // Only go to the next page if the current element is a whitespace element
        const isElementWhiteSpace =
            structuralElement?.paragraph?.elements[0]?.textRun !== null &&
            structuralElement?.paragraph?.elements[0]?.textRun?.content?.trim() === "";

        // Check if the element is between the <border> tags
        if (structuralElement?.paragraph?.elements[0]?.textRun?.content?.includes("<border>")) {
            hasBorder = true;
        } else if (structuralElement?.paragraph?.elements[0]?.textRun?.content?.includes("</border>")) {
            hasBorder = false;
        }

        const lastElementHasImage = elementsOnThisPage[elementsOnThisPage.length - 1]?.paragraph?.numberOfImages() > 0;

        if (
          (
            (
              // This checks if the page is past a certain height and it's not the first paragraph under a title,
              // to ensure that titles are not empty at the bottom of a page.
              !isFirstParagraphOfChapter
              && currentHeight > maxPageHeight
              && isElementWhiteSpace
              // Ensure that there isn't a page break between the image and the image description.
              && !lastElementHasImage
            )
            || (
              // Or we will break if we have moved to a new chapter (and last chapter isn't empty, i.e we are on the first chapter, just starting
              // The new chapter only causes a break, if we have a Heading 1 (biggest heading) header
              (
                  movedToNewChapter
                  && previousChapter
                  && (isHeadingOneChapter || isHeadingTwoChapter)
                  && !isRightAfterOtherHeader
              )
              // We never break on an image, on a table, and when bullet points are going to come next.
              && !isTable
              && !hasImage
              && !isNextElementABulletPoint
            )
            // Or we will break, and make the page, if this is the last element in the book, this takes full priority
            // As you have to make a last page.
            || this.structuralElements[this.structuralElements.length - 1] === structuralElement
          )
          // However, if there are no elements on this page, we don't make it
          && elementsOnThisPage.length > 0
          && !hasBorder
        ) {
          currentHeight = 0;

          const newPage = new Page(
              pageCount,
              elementsOnThisPage[0].containingChapter,
              elementsOnThisPage,
          );

          elementsOnThisPage.forEach(structuralElement => {
              structuralElement.setPage(newPage);
              structuralElement.getChildStructuralElements().forEach(childStructuralElement => {
                  childStructuralElement.setPage(newPage);
              });
          });
          elementsOnThisPage = [];

          // Check the page actually has some content.
          if (newPage.annotatedStructuralElements.some(
              (element) => {
                  if (element.table) return true;
                  if (element.paragraph) {
                      return element.paragraph.fullText.trim().length > 0 || element.paragraph.numberOfImages() > 0;
                  }
                  return false;
              }
          )) {
              if (!isHiddenChapter) {
                  pages.push(newPage);
                  pageCount++;
              }
          }

          // Then we look if we need to add a new page for the chapter's question page.
          if (previousChapter.questionList && movedToNewChapter) {
              const newPage = new Page(
                  pageCount,
                  previousChapter.questionList.chapter,
                  [],
                  previousChapter.questionList
              );

              pages.push(newPage);

              previousChapter.questionList.chapter.startPage = pageCount;
              previousChapter.questionList.chapter.endPage = pageCount;
              pageCount++;
          }
      }

      if (structuralElement.containingChapter.startPage == null) {
        structuralElement.containingChapter.startPage = pageCount;
      }

      if (!isTableOfContents && !isHiddenChapter) {
        elementsOnThisPage.push(structuralElement);
      }

      previousElement = structuralElement;
    });
    this.pages = pages;
    return pages;
  }
}