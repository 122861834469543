export const MODAL_DETAILS = {
    DELETE_SUMMARY: {
        id: "deleteSummary",
        title: 'Samenvatting verwijderen',
        text: 'Weet je zeker dat je deze samenvatting permanent wilt verwijderen? Deze zal niet meer zichtbaar zijn in de app.',
        buttonText: 'Verwijder samenvatting',
        isRedButton: true
    },
    PUBLISH_SUMMARY: {
        id: "publishSummary",
        title: 'Samenvatting publiceren',
        text: 'Weet je zeker dat je deze samenvatting wilt publiceren? Deze zal direct zichtbaar zijn in de app.',
        buttonText: 'Publiceer samenvatting',
        isRedButton: false
    },
    UNPUBLISH_SUMMARY: {
        id: "unPublishSummary",
        title: 'Publicatie ongedaan maken',
        text: 'Weet je zeker dat je deze publicatie ongedaan wil maken? De samenvatting wordt niet verwijderd, maar zal niet meer zichtbaar zijn in de app.',
        buttonText: 'Maak publicatie ongedaan',
        isRedButton: true
    },
    DELETE_QUESTION_LIST: {
        id: 'deleteQuestionList',
    },
    DELETE_FLASHCARD_LIST: {
        id: 'deleteFlashcardList',
    },
    DELETE_USER: {
        id: 'deleteUser'
    },
    LOGOUT_USER: {
        id: 'logOut'
    }
}
