export default class Subject {
  constructor({id, title, summaryCount, questionsCount, topicsCount, isPublished, collegeYear, university, study, productCode}) {
    this.id = id;
    this.title = title;
    this.summaryCount = summaryCount;
    this.questionsCount = questionsCount;
    this.topicsCount = topicsCount;
    this.isPublished = isPublished;
    this.collegeYear = collegeYear;
    this.university = university;
    this.study = study;
    this.productCode = productCode;
  }

  static parseDataFromObject(data) {
    return new Subject(data);
  }
}