<template>
  <div class="dropdown-wrapper">
    <p class="label">{{ label }}</p>

    <div v-if="isDropdownShown" class="dropdown-mask" @click="toggleDropdown()"/>
    <div class="basic-dropdown" @click="toggleDropdown()">
      <div class="dropdown-text-container">
        <input :id="fieldName"
               v-model="selected"
               :class="{'has-error': errorMessages?.length || hasError }"
               :name="fieldName"
               :disabled="isReadOnly"
               :placeholder="placeholder"
               class="input"
               readonly>
        <DisplayedIcon :active-src="require('../../assets/icons/ic_dropdown_up.svg')"
                       :displayed-src="require('../../assets/icons/ic_dropdown.svg')"
                       :is-active="isDropdownShown"
                       class="caret-icon"/>
      </div>
      <div v-if="isDropdownShown" id="option-list" class="dropdown-list">
        <div class="option-list-inner">
          <div v-for="option in dropdownOptions"
               :id="`option-${option.id}`"
               :key="option.id"
               class="option"
               @click="selectOption(option)">
            {{ option.name }}
          </div>
        </div>
      </div>
    </div>
    <p v-for="errorMessage of errorMessages" :key="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import DisplayedIcon from "@/components/elements/DisplayedIcon";
import {ref, watch} from "vue";
import Validator from "@/utils/yo-validator/YoValidator";
import {formatCollegeYear} from "@/utils/helpers/DetailsHelper";

export default {
  name: "BasicDropdown",
  components: {
    DisplayedIcon,
  },
  props: {
    label: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    dropdownOptions: {
      type: Array,
      required: true
    },
    rules: {
      required: true,
      default: ''
    },
    value: {
      required: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update'],
  setup(props, {emit}) {
    const {field, errorMessages, setFieldData} = Validator.register(props);
    const selected = ref();
    const isDropdownShown = ref(false);

    function toggleDropdown() {
      if (props.isReadOnly) {
        return;
      }
      isDropdownShown.value = !isDropdownShown.value
    }

    function selectOption(option) {
      selected.value = option.displayValue ? option.displayValue : option.name;
      setFieldData(option);
      emit('update', option);
    }

    setValueOnCreate();

    function setValueOnCreate() {
      if (props.value) {
        if (typeof props.value.name !== 'number') {
          selected.value = props.value.name;
        } else {
          selected.value = formatCollegeYear(props.value.name);
        }
        setFieldData(props.value);
      }
    }

    return {
      isDropdownShown,
      toggleDropdown,
      selected,
      selectOption,
      field,
      errorMessages,
      setFieldData,
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
  @include hover-active-pointer;
  position: relative;
  width: rem(350);

  .input {
    margin: 0;
    color: var(--main-font-color);
    @include hover-active-pointer;
  }

  .dropdown-mask {
    @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: z('dropdown', 'mask'));
    background-color: transparent;
  }

  .dropdown-text-container {
    align-items: center;
    display: flex;
  }

  .dropdown-list {
    position: absolute;
    width: 100%;
    box-shadow: 0 rem(10) rem(30) rem(-6) rgba(51, 53, 68, 0.05);
    background-color: white;
    border-radius: rem(10);
    margin-top: rem(8);
    padding: rem(16) rem(8);
    z-index: z('dropdown', 'options');

    .option-list-inner {
      max-height: rem(150);
      height: auto;
      overflow-y: auto;

      .option {
        padding: rem(8) rem(16);

        @include hover-active-pointer {
          border-radius: rem(10);
        }
      }
    }
  }

  .caret-icon {
    @include position(absolute, $right: rem(12));
    width: rem(30);
    height: rem(30);
  }
}

.users-form {
  .dropdown-list {
    .option-list-inner {
      height: auto;
      overflow: hidden;
      padding: rem(12);
    }
  }
}
</style>
