import {ERROR_CODES} from '../errorCodes'
import ErrorBase from './ErrorBase'

export default class ValidationError extends ErrorBase {
    constructor(error, message) {
        super(error, message);
        this.code = ERROR_CODES.VALIDATION_ERROR;
        this.message = {
            nl: 'Voor een geldige code in',
        }
    }
}