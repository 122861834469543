import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('@/views/manager/Layout');
const QuestionOverview = () => import('@/views/manager/question/QuestionOverview');
const QuestionListOverview = () => import('@/views/manager/question/QuestionListOverview');
const CreateQuestionList = () => import('@/views/manager/question/QuestionListCreate');
const EditQuestionList = () => import('@/views/manager/question/QuestionListEdit');


export const ROUTE_NAMES_QUESTIONS = Object.freeze({
  OVERVIEW: 'QuestionsOverview',
  LIST_OVERVIEW: 'QuestionsListOverview',
  QUESTION_LIST_CREATE: 'QuestionsListCreate',
  QUESTION_LIST_EDIT: 'QuestionsListEdit',
});

export const questions = {
  path: '/questions',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  children: [
    {
      path: 'overview',
      name: ROUTE_NAMES_QUESTIONS.OVERVIEW,
      component: QuestionOverview,
    },
    {
      path: 'overview/:listId',
      name: ROUTE_NAMES_QUESTIONS.LIST_OVERVIEW,
      component: QuestionListOverview,
    },
    {
      path: 'question-list/create/:listId',
      name: ROUTE_NAMES_QUESTIONS.QUESTION_LIST_CREATE,
      component: CreateQuestionList
    },
    {
      path: 'question-list/edit/:questionListId',
      name: ROUTE_NAMES_QUESTIONS.QUESTION_LIST_EDIT,
      component: EditQuestionList
    },
  ]
}