import AuthGuard from '@/router/guards/auth';
import ManagerGuard from '@/router/guards/manager';
import CreateSummary from "@/views/manager/summary/CreateSummary";
import SummaryDetailOverview from "@/views/manager/summary/SummaryDetailOverview";
// import LibraryOverview from "@/views/manager/summary/LibraryOverview";
import SummarySupplementsOverview from "@/views/manager/summary/SummarySupplementsOverview";
import SummaryFragmentsOverview from "@/views/manager/summary/SummaryFragmentsOverview";
import ViewSummary from "@/views/manager/summary/ViewSummary";

const ManagerLayout = () => import('../../views/manager/Layout');
const Overview = () => import('../../views/manager/summary/SummaryOverview');
// const LibraryOverview = () => import('../../views/manager/summary/LibraryOverview');
const Preview = () => import('@/reader/views/Read');
const PdfConvert = () => import('@/reader/views/SummaryPdfConvert.vue');
const ReadIndex = () => import('@/reader/views/ReadIndex.vue');

export const ROUTE_NAMES_SUMMARY = Object.freeze({
    OVERVIEW: 'SummaryOverview',
    LIBRARY: 'SummaryLibrary',
    CREATE: 'SummaryCreate',
    DETAILS: 'SummaryDetails',
    VIEW: 'SummaryView',
    PREVIEW: 'SummaryPreview',
    CONVERT: 'Convert',
    // EDIT: 'SummaryEdit',
    SUPPLEMENTS_OVERVIEW: 'SummarySupplementsOverview',
    FREE_FRAGMENTS_OVERVIEW: 'SummaryFreeFragmentsOverview',
    READ_INDEX: 'SummaryReadIndex'
});

export const summary = {
    path: '/summary',
    meta: {
        guard: AuthGuard
    },
    component: ManagerLayout,
    children: [
        {
            //path: 'summary/:summaryId?',
            path: 'overview',
            name: ROUTE_NAMES_SUMMARY.OVERVIEW,
            component: Overview
        },
        // {
        //     path: 'library',
        //     name: ROUTE_NAMES_SUMMARY.LIBRARY,
        //     component: LibraryOverview
        // },
        {
            path: 'create',
            name: ROUTE_NAMES_SUMMARY.CREATE,
            component: CreateSummary,
            meta: {
                guard: ManagerGuard
            }
        },
        {
            path: 'details/:summaryId',
            name: ROUTE_NAMES_SUMMARY.DETAILS,
            component: SummaryDetailOverview
        },
        // {
        //     path: 'edit/:summaryId',
        //     name: ROUTE_NAMES_SUMMARY.EDIT,
        //     component: EditSummary,
        //     meta: {
        //         guard: ManagerGuard
        //     }
        // },
        {
            path: 'view/:summaryId',
            name: ROUTE_NAMES_SUMMARY.VIEW,
            component: ViewSummary
        },
        {
            path: 'preview/:summaryId',
            name: ROUTE_NAMES_SUMMARY.PREVIEW,
            component: Preview
        },
        {
            path: 'preview/:summaryId/index',
            name: ROUTE_NAMES_SUMMARY.READ_INDEX,
            component: ReadIndex
        },
        {
            path: 'preview/:summaryId/convert',
            name: ROUTE_NAMES_SUMMARY.CONVERT,
            component: PdfConvert
        },
        {
            path: 'supplements-overview/:summaryId',
            name: ROUTE_NAMES_SUMMARY.SUPPLEMENTS_OVERVIEW,
            component: SummarySupplementsOverview
        },
        {
            path: 'free-fragments-overview/:summaryId',
            name: ROUTE_NAMES_SUMMARY.FREE_FRAGMENTS_OVERVIEW,
            component: SummaryFragmentsOverview
        }
    ]
}
