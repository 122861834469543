<template>
  <div class="form-wrapper">
    <form @submit.prevent="handleSubmit(sendFormData)" novalidate>
      <div class="form-content-wrapper">
        <slot name="form-content"/>
      </div>
      <slot name="button-submit" :is-invalid="isInvalid"/>
    </form>
  </div>
</template>

<script setup>
import Validator from '@/utils/yo-validator/YoValidator';
import {defineProps} from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: false
  },
  sendFormData: {
    type: Function,
    required: true
  },
  onFormDataUpdate: {
    type: Function,
    required: false
  },
  mode: {
    type: Number,
    default: 3,
    required: false
  }
})

// Get handle submit function
const {handleSubmit, isInvalid, validateFormFields} = Validator.registerForm(props);
</script>

<style lang="scss">
$backgroundColor: white;
$borderColor: var(--gray-light-04);
$textColor: var(--black-light);
$placeholderColor: var(--gray-light);
$error-color: var(--red-light);

/* form elements */
input, select, textarea {
  font-family: var(--main-font-family);
  border: none;
  width: 100%;

  @include placeholder {
    color: $placeholderColor;
    font-family: var(--open-sans);
    font-size: rem(14);
    letter-spacing: 0;
    line-height: rem(20);
  }

  &:focus {
    outline: none;
  }
}

label {
  width: 100%;
}

input {
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

input[type="date"] {
  &::-webkit-input-placeholder {
    visibility: hidden !important;
  }
}

input[type="number"] {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

textarea {
  resize: none;

  /* set the width to 0.1px so it is not visible but the scrolling function still exists */
  &::-webkit-scrollbar {
    width: 0.1px;
  }
}

.form-group {
  margin-bottom: rem(8);
}

.input-label {
  position: relative;
}

.form-input-title {
  font-size: rem(12);
  font-weight: normal;
  line-height: rem(15);
  margin-bottom: rem(9);
  text-align: left;
}

.form-input {
  background-color: var(--gray-light-a);
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(3);
  color: $textColor;
  font-size: rem(16);
  height: rem(40);
  padding: 0 rem(15) 0 rem(49);

  &:focus {
    border-color: var(--black-light);

    &.field-error {
      border: rem(1) solid var(--red-light);
    }
  }
}

.form-input-textarea {
  padding: rem(15);
  border-radius: rem(12);
  background-color: var(--gray-light-a);
  font-size: rem(16);
  color: $textColor;
  height: rem(300);
}

.form-file-select {
  border: rem(1) solid $borderColor;
  border-radius: rem(8);
  background-color: $backgroundColor;
}

/* texts */
.text-error {
  color: var(--red-light);
  font-size: rem(12);
  line-height: rem(15);
  margin-top: rem(10);
  text-align: right;
}

.text-error-right {
  @extend .text-error;
  text-align: right;
}

.field-error {
  border: rem(1) solid var(--red-light);
}

.read-only-input {
  @include on-hover {
    cursor: default;
  }
}

/** Icons **/
.input-icon {
  @include position(absolute, $top: 50%, $left: rem(15));
  transform: translateY(-50%);
  height: rem(24);
  width: rem(24);

  &.has-error {
  }
}

.without-icon {
  padding-left: rem(15);
}
</style>
