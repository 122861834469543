import {actions} from './actions';
import {getters} from './getters';
import {apiActions} from './apiActions';
import {defineStore} from "pinia";
import SummaryTableFilter from "@/models/tables/SummaryTableFilter";
import SubjectTableFilter from "@/models/tables/SubjectTableFilter";

export const state = () => ({
    selectedSummary: null,
    universities: [],
    summaryChapters: [],
    /** @type {SummaryTableFilter} */
    currentListFilter: SummaryTableFilter.createEmpty(),
    /** @type {SubjectTableFilter} */
    currentSubjectFilter: SubjectTableFilter.empty(),
    /**
     * The data returned from the API call, will contain summaries
     * @type {PaginationData} **/
    paginationDataResponse: null,
});

export const useSummaryStore = defineStore('summary', {
    state,
    getters,
    actions: {...actions, ...apiActions}
});
