import AuthGuard from '@/router/guards/auth';

const ManagerLayout = () => import('@/views/manager/Layout');
const FlashcardOverview = () => import('@/views/manager/flashcard/FlashcardOverview');
const FlashcardListOverview = () => import('@/views/manager/flashcard/FlashcardListOverview');
const CreateFlashcardList = () => import('@/views/manager/flashcard/FlashcardListCreate');
const EditFlashcardList = () => import('@/views/manager/flashcard/FlashcardListEdit');

export const ROUTE_NAMES_FLASHCARDS = Object.freeze({
  OVERVIEW: 'FlashcardOverview',
  LIST_OVERVIEW: 'FlashcardListOverview',
  FLASHCARD_LIST_CREATE: 'FlashcardListCreate',
  FLASHCARD_LIST_EDIT: 'FlashcardListEdit',
});

export const flashcards = {
  path: '/flashcards',
  meta: {
    guard: AuthGuard
  },
  component: ManagerLayout,
  // children: [
  //   {
  //     path: 'overview',
  //     name: ROUTE_NAMES_FLASHCARDS.OVERVIEW,
  //     component: FlashcardOverview,
  //   },
  //   {
  //     path: 'overview/:flashcardListId',
  //     name: ROUTE_NAMES_FLASHCARDS.LIST_OVERVIEW,
  //     component: FlashcardListOverview,
  //   },
  //   {
  //     path: 'create/:flashcardListId',
  //     name: ROUTE_NAMES_FLASHCARDS.FLASHCARD_LIST_CREATE,
  //     component: CreateFlashcardList
  //   },
  //   {
  //     path: 'edit/:flashcardListId',
  //     name: ROUTE_NAMES_FLASHCARDS.FLASHCARD_LIST_EDIT,
  //     component: EditFlashcardList
  //   },
  // ]
}