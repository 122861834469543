export const getters = {
    /** Subjects */
    getSubjects: (state) => state.paginationDataResponse?.data ?? [],

    /** summaries **/
    getSummaries: (state) => {
        return state.paginationDataResponse?.data ?? [];
    },

    getSelectedSummary: (state) => state.selectedSummary,

    getChapters: (state) => state.summaryChapters,

    /** universities **/
    getUniversities: (state) => state.universities,
    
    /** filter **/
    getCurrentListFilter: (state) => state.currentListFilter,
    getCurrentSubjectFilter: (state) => state.currentSubjectFilter,
    
    getTotalItemCount: (state) => state.paginationDataResponse?.totalItemCount ?? 0,
    getPaginationDataResponse: (state) => state.paginationDataResponse,
}