import QuestionList from "@/reader/model/question/QuestionList";
import Question from "@/reader/model/question/Question";
import Chapter from "@/reader/model/summary/content/Chapter";

export default class QuestionListDetails extends QuestionList {

  /**
   * A psuedo chapter that contains the question and allows for them to be answered.
   * This is added to the end of the actual chapter these questions are added to.
   * @type {Chapter}
   */
  chapter;


  constructor({id, chapterId, title, description, answerDisplaying, questions, summaryId}) {
    super({id, title, description});
    this.chapterId = chapterId;
    this.summaryId = summaryId;
    this.answerDisplaying = answerDisplaying;
    this.questions = questions.map(question => Question.parseDataFromObject(question));
    this.chapter = this.convertToChapter();
  }

  hasAnsweredAll(){
    return this.questions.every(question => question.getIsAnswered());
  }

  showAnswersInBetween() {
    return this.answerDisplaying === 2;
  }

  /**
   * Converts this to a chapter object to be insterted into the books content.
   * @return {Chapter}
   */
  convertToChapter(){
    const chapter = new Chapter((this.chapterId ?? "end") + "-question-list", this.title, 1, -1, -1);
    chapter.setQuestionListDetails(this);
    return chapter;
  }


  static parseDataFromObject(data) {
    return new QuestionListDetails(data);
  }
}
