import BaseTableFilter from "@/models/tables/baseTableFilter";

export default class SummaryTableFilter extends BaseTableFilter {    
    /** @type {number | null} */
    isPublished = null;
    
    constructor(pageNumber, isPublished, searchQuery, sortingOption) {
        super(pageNumber, searchQuery, sortingOption);
        this.isPublished = isPublished;
    }
    
    static empty() {
        return new SummaryTableFilter(1,null, null, null);
    }
    
    isEmpty(){
        return this.isPublished === null && super.isEmpty();
    }
    
    isEqual(other){
        return this.isPublished === other.isPublished && super.isEqual(other);
    }
    
}