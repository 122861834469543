<template>
  <div :id="toastId" class="toast-wrapper">

    <div class="toast-content">
      <!-- Icon -->
      <img v-if="toast.icon" :src="toast.icon" class="icon" alt=""/>

      <!-- Text -->
      <div class="text-wrapper">
        <p class="title">{{ toast.title }}</p>
        <p v-if="toast.subtitle" v-html="toast.subtitle" class="subtext"></p>
        <slot name="subtext"/>
      </div>
    </div>

  </div>
</template>

<script>
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import Toast from "@/utils/handlers/toastHandler/Toast";

export default {
  name: "Toast",
  props: {
    toast: {
      type: Toast,
      required: true
    },
    debounce: {
      default: 5000
    },
  },
  setup(props) {
    const toastId = `toast${props.toast.id}`;

    if (props.toast.options.autoClose) {
      initializeTimeout();
    }

    function initializeTimeout() {
      setTimeout(() => {
        close();
      }, props.debounce);
    }

    function close() {
      ToastHandler.closeToastById(props.toast.id);
    }

    return {
      toastId,
      close,
    }
  }
}
</script>

<style scoped lang="scss">
.toast-wrapper {
  background-color: var(--green-toast);
  border-radius: rem(8);
  height: rem(52);
  border-left: rem(8) solid var(--green-light);
  max-width: rem(360);
  transition: 0.3s all ease-in-out;
  padding: rem(15) rem(20) rem(15) rem(16);

  .toast-content {
    align-items: center;
    display: flex;
    position: relative;
  }

  .icon {
    height: rem(22);
    margin-right: rem(12);
    width: rem(22);
  }
}

// Toast - Not allowed
.not-allowed-toast {
  border-left: rem(8) solid var(--red-light);
}

// Text
.text-wrapper {
  text-align: left;

  .title {
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
    margin-bottom: rem(2);
    color: #fff;
    white-space: nowrap;
  }

  .subtext {
    color: var(--white-main);
    font-size: rem(14);
    font-weight: bold;
    letter-spacing: 0;
    line-height: rem(20);

    ::v-deep a {
      text-align: left;
    }
  }
}
</style>
