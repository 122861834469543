export default class BaseTableFilter {

  pageNumber = 1;

  /** @type {string | null} */
  searchQuery = null;

  /** @type {SortingOption | null} */
  sortingOption = null;

  constructor(pageNumber, searchQuery, sortingOption) {
    this.pageNumber = pageNumber;
    this.searchQuery = searchQuery;
    this.sortingOption = sortingOption;
  }

  static empty() {
    return new BaseTableFilter(1, null, null);
  }

  isEmpty() {
    return this.searchQuery === null && this.sortingOption === null;
  }

  nextPage() {
    this.pageNumber++;
  }

  previousPage() {
    this.pageNumber--;
  }

  resetPage() {
    this.pageNumber = 1;
  }

  isEqual(other) {
    return this.pageNumber === other.pageNumber &&
      this.searchQuery === other.searchQuery &&
      this.sortingOption === other.sortingOption;
  }
}