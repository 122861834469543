/** routes **/
import {auth} from '@/router/modules/auth'
import NotFound from '@/views/NotFound'
import Logout from "@/views/Logout";
import {summary} from "@/router/modules/summary";
import {users} from "@/router/modules/users";
import {feedback} from "@/router/modules/feedback";
import {questions} from '@/router/modules/questions';
import {flashcards} from '@/router/modules/flashcards';

export const ROUTE_NAMES_ROOT = Object.freeze({
  LOGOUT: 'Logout',
  NOT_FOUND: 'Not Found',
});

/** routes without layout **/
export const root = [
  {
    path: '/logout',
    name: ROUTE_NAMES_ROOT.LOGOUT,
    component: Logout
  },
  {
    // not found
    path: '/not-found',
    name: ROUTE_NAMES_ROOT.NOT_FOUND,
    component: NotFound
  },
]

export default root.concat([summary, auth, users, feedback, questions, flashcards]);