<template>
  <div class="top-section">
    <div class="title-btn-wrapper">
      <h2 class="title">{{ title }}</h2>
      <div class="button-wrapper">
        <slot name="extra-button"/>
        <DButton v-if="hasButton"
                :button-text="buttonText"
                button-type="button"
                :class="['page-title-button', {'white-button':isWhiteButton, 'hide-btn': hideButton, 'modal-button-red': isRedButton}]"
                @click="onClickButton"/>
      </div>
      <div class="error-message sync-error" v-if="syncErrorMessage !== null && syncErrorMessage.length > 0">{{syncErrorMessage}}</div>
      <SyncButton v-if="isSyncButton" @on-click-sync="onClickButton" :sync-status="syncStatus"/>

      <DetailActions v-if="hasMoreActions" @on-click-dropdown-item="performAction" class="details-dropdown"/>
    </div>

    <div v-if="hasReturnButton" class="return-btn-wrapper">
      <router-link :to="{name: breadCrumbData, params: breadcrumbParams}">
        <div class="return-btn">
          <img alt="return" src="../../../assets/icons/ic_return.svg">
          <p class="return-btn-text">Ga terug naar overzicht</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import DButton from "@/components/elements/DButton";
import {useRouter} from "vue-router";
import DetailActions from "@/components/partials/summary/DetailActions";
import {useRootStore} from "@/store";
import {computed, ref} from "vue";
import SyncButton from "@/components/partials/summary/SyncButton";

export default {
  name: "PageTitle",
  components: {
    SyncButton,
    DButton,
    DetailActions
  },
  props: {
    title: {
      required: true,
    },
    hasReturnButton: {
      required: false,
      default: false,
      type: Boolean
    },
    hasButton: {
      required: false,
      default: false,
      type: Boolean
    },
    buttonText: {
      required: false,
      type: String
    },
    hideButton: {
      required: false,
      type: Boolean,
      default: false
    },
    hasMoreActions: {
      required: false,
      type: Boolean,
      default: false
    },
    isWhiteButton: {
      required: false,
      type: Boolean,
      default: false
    },
    isSyncButton: {
      required: false,
      type: Boolean,
      default: false
    },
    syncStatus: {
      required: false,
      type: String
    },
    syncErrorMessage: {
      required: false,
      default: null,
      type: String
    },
    isRedButton: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['on-click-btn','perform-action'],
  setup(props, {emit}) {
    const router = useRouter();
    const rootStore = useRootStore();

    const breadCrumbData = computed(() => {
      const breadCrumbName = ref(rootStore.getBreadcrumbName);

      if (!breadCrumbName.value){
        router.getRoutes().forEach(route => {
          if (router.options.history.state.back === route.path) {
            breadCrumbName.value = route.name;
          }
        })
      }

      return breadCrumbName.value;
    })

    const breadcrumbParams = computed(() => rootStore.getBreadcrumbParams);

    function onClickButton() {
      emit('on-click-btn');
    }

    /** On click of dropdown item **/
    function performAction(action) {
      emit('perform-action', action)
    }

    return {
      onClickButton,
      breadCrumbData,
      performAction,
      breadcrumbParams
    }
  }
}
</script>

<style lang="scss" scoped>
.top-section {

  .title-btn-wrapper {
    display: flex;
    align-items: center;

    .hide-btn {
      display: none;
    }

    .details-dropdown {
      margin-left: rem(16);
    }
  }

  .button-wrapper {
    display: flex;
    gap: rem(8);
    align-items: center;
  }

  .title {
    font-size: rem(28);
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    margin: 0;
    flex-grow: 1;
  }

  .return-btn-wrapper {
    margin-top: rem(16);

    .return-btn {
      height: rem(24);
      width: 100%;
      display: flex;
      align-items: center;
      gap: rem(12);
    }

    .return-btn-text {
      margin: 0;
      color: var(--main-font-color);
      font-size: rem(16);
      letter-spacing: 0;
      line-height: rem(20);
    }
  }
}

.sync-error{
  padding-right: rem(20);
  font-size: rem(20);
  font-weight: bold;
}
</style>