import {ERROR_CODES} from '../errorCodes'
import ErrorBase from './ErrorBase'

export default class NotFoundSubject extends ErrorBase {
    constructor(error, message) {
        super(error, message);
        this.code = ERROR_CODES.NOT_FOUND_SUBJECT;
        this.message = {
            nl: 'Onderwerp niet gevonden',
        }
    }
}