import BaseTableFilter from "@/models/tables/baseTableFilter";

export default class SummaryTableFilter extends BaseTableFilter {
  /**
   *
   * @param pageNumber {number}
   * @param bachelorYearNumber {number}
   * @param isPublished {boolean}
   * @param searchQuery {string}
   * @param sortingOption
   */
  constructor(pageNumber, bachelorYearNumber, isPublished, searchQuery, sortingOption) {
    super(pageNumber, searchQuery, sortingOption);
    this.bachelorYearNumber = bachelorYearNumber;
    this.isPublished = isPublished;
  }

  static createEmpty() {
    return new SummaryTableFilter(1, null, null, null, null);
  }

  isEmpty() {
    return this.bachelorYearNumber === null && this.isPublished === null && super.isEmpty();
  }

  isEqual(other) {
    return this.bachelorYearNumber === other.bachelorYearNumber &&
      this.isPublished === other.isPublished && super.isEqual(other);
  }

}