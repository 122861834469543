import ToastOptions from "@/utils/handlers/toastHandler/ToastOptions";

class ToastContent {
  constructor({id, title, subtitle, icon, options}) {
    this.id = id;
    this.title = title;
    this.subtitle = subtitle;
    this.icon = icon;
    this.options = options;
  }
}

export const TOAST_CONTENTS = {
  LINK_SENT: new ToastContent({
    id: 1,
    title: null,
    subtitle: 'Link opnieuw verstuurd!',
    icon: require('../../../assets/icons/ic_check_white.svg'),
    options: new ToastOptions(3000)
  }),
  PASSWORD_RESET_SUCCES: new ToastContent({
    id: 2,
    title: null,
    subtitle: 'Wachtwoord succesvol gewijzigd',
    icon: require('../../../assets/icons/ic_check_white.svg'),
    options: new ToastOptions(3000)
  }),
  BASIC_TOAST: (title) => new ToastContent({
    id: 3,
    title: title,
    subtitle: null,
    icon: require('../../../assets/icons/ic_check_white.svg'),
    options: new ToastOptions(3000)
  }),
}
