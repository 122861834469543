export const detailNames = [
    {
        value: 'Titel',
        id: 'title'
    },
    {
        value: 'Productcode',
        id: 'productCode'
    },
    {
        value: 'Beschrijving',
        id: 'description'
    },
    {
        value: 'Gdoc link',
        id: 'googleDocsUrl'
    },
    {
        value: 'Gesynchroniseerd',
        id: 'syncedAt'
    },
    {
        value: 'Status',
        id: 'isPublished'
    },
    {
        value: 'Universiteit',
        id: 'university'
    },
    {
        value: 'Collegejaar',
        id: 'collegeStartYear'
    },
    {
        value: 'Bachelorjaar',
        id: 'bachelorYearNumber'
    },
    {
        value: 'Periode(s)',
        id: 'periods'
    },
    {
        value: 'Tentamen',
        id: 'examDate'
    },
    {
        value: 'Beschikbaar op',
        id: 'availableDate'
    },
]

export const detailNamesQuestions = [
    {
        value: 'Productcode',
        id: 'productCode'
    },
    {
        value: 'ID',
        id: 'id'
    },
    {
        value: 'Oefenvraagvak ID',
        id: 'externalId'
    },
    {
        value: 'Universiteit',
        id: 'university'
    },
    {
        value: 'Studie',
        id: 'study'
    },
    {
        value: 'Vak',
        id: 'title'
    },
    {
        value: 'Collegejaar',
        id: 'collegeYear'
    },
]


export const detailNamesFlashcards = [
    {
        value: 'Productcode',
        id: 'productCode'
    },
    {
        value: 'ID',
        id: 'id'
    },
    {
        value: 'Flashcardvak ID',
        id: 'externalId'
    },
    {
        value: 'Aankooplink',
        id: 'shopUrl'
    },
    {
        value: 'Laatst geupdate',
        id: 'updatedAt'
    },
    {
        value: 'Universiteit',
        id: 'university'
    },
    {
        value: 'Studie',
        id: 'study'
    },
    {
        value: 'Vak',
        id: 'title'
    },
    {
        value: 'Collegejaar',
        id: 'collegeYear'
    },
]


export function formatCollegeYear(year){
    const nextYear = year + 1;
    return year.toString().slice(2) + "/" + nextYear.toString().slice(2);
}
